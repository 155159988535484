import GlobalProvider from '../context/GlobalContext'
import { ToastProvider } from 'react-toast-notifications'
import { QueryClient, QueryClientProvider } from 'react-query'
import Head from 'next/head'
import { MixpanelProvider } from 'react-mixpanel-browser';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

import '../styles/globals.css'
import '../styles/terms-of-use.scss'
import '../styles/privacy-policy.scss'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { AppLayout } from '../components'

function App({ Component, pageProps }) {
  return (
    <ToastProvider>
      <MixpanelProvider>
      <Head>
        <link rel="icon" href="/favicon.png" />
        <link
          rel="stylesheet"
          href="https://use.typekit.net/bub1dnu.css"></link>
        <link
          rel="preload"
          href="/fonts/GTWalsheim/GT-Walsheim-Medium.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/GTWalsheim/GT-Walsheim-Regular.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/GTWalsheim/GT-Walsheim-Light.ttf"
          as="font"
          crossOrigin=""
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
            <AppLayout>
              <Component {...pageProps} />
            </AppLayout>
        </GlobalProvider>
      </QueryClientProvider>
      </MixpanelProvider>
    </ToastProvider>
  )
}

export default App
